import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TestForm = _resolveComponent("TestForm")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    "back-button": "/tests",
    name: _ctx.title
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_TestForm, {
        onTitle: _ctx.setTitle,
        id: _ctx.$route.params.id,
        key: _ctx.$route.params.id
      }, null, 8, ["onTitle", "id"]))
    ]),
    _: 1
  }, 8, ["name"]))
}