
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Container from "../layout/Container.vue";
import TestForm from "@/components/tests/Test.vue";
import mixin from "@/mixin";

export default defineComponent({
  name: 'Test',
  components: {
    Container,
    TestForm,
  },

  data() {
    return {
      title: '',
    };
  },

  mixins: [
    mixin
  ],

  methods: {
    setTitle(title: string) {
      this.title = title;
    }
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
